import { createContext, useEffect, useReducer } from "react";

export const BreadcrumbContext = createContext(null);

export function BreadcrumbProvider({ children }) {
  function reducer(state, action) {
    switch (action.type) {
      case "HOME":
        return {
          ...state,
          home: action.home,
        };
      case "PARENT":
        const { title, parent } = action;
        return {
          ...state,
          title,
          parent,
        };
      default:
        return state;
    }
  }

  const [breadcrumbs, dispatchBreadcrumb] = useReducer(reducer, {
    home: {
      href: "/app",
      title: "Home",
    },
    title: "Courses",
  });

  useEffect(() => {
    document.title = breadcrumbs.title;
  }, [breadcrumbs]);

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbs,
        dispatchBreadcrumb,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
}
