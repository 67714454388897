import { Alert, Box } from "@mui/material";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { useContext, useEffect, useState } from "react";
import { SecurityContext } from "../service/SecurityService";
import API from "../api";

export function PlaylistDetails({ playlistId }) {
  const { isReady } = useContext(SecurityContext);
  const [error, setError] = useState();
  const [content, setContent]= useState("");

  useEffect(() => {
    async function load() {
      try {
        const c = await API.getPlaylistContent(playlistId, "full.md");
        setContent(c);
        setError("");
      } catch (e) {
        setError(`error: ${e}`);
      }
    }

    if (isReady) {
      load();
    }
  }, [isReady, playlistId]);

  if (error !== "") {
    return <Alert severity="error">{error}</Alert>;
  }
  return (
    <Box sx={{ m: 4 }}>
      <MarkdownPreview source={content} />
    </Box>
  );
}
