import { Box } from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import API from "../api";
import { FINISHED, IN_PROGRESS } from "../api/const";
import { BreadcrumbContext } from "../service/BreadcrumbService";
import { SecurityContext } from "../service/SecurityService";

export function EmbeddedPlayer({
  track,
  proceed,
  onEndedTrack,
  onStartPlaying,
}) {
  const [playing, setPlaying] = useState(false);
  const { token } = useContext(SecurityContext);
  const video = useRef(null);
  const { t } = useTranslation();

  const { dispatchBreadcrumb } = useContext(BreadcrumbContext);
  const title = t("currentCourse", { title: track ? track.title : "" });
  const currentTitle = t("myLibrary");
  const dispatchBCCallback = useCallback(dispatchBreadcrumb, [
    title,
    currentTitle,
    dispatchBreadcrumb,
  ]);
  useEffect(() => {
    dispatchBCCallback({
      type: "PARENT",
      title,
      parent: {
        href: "/app",
        title: currentTitle,
      },
    });
  }, [title, currentTitle, dispatchBCCallback]);

  useEffect(() => {
    if (track) {
      video.current.src = `/api/v1/files/track-${track.id}.mp4?apiKey=${token}`;
      if (proceed) {
        video.current.play();
        setPlaying(true);
      }
    }
  }, [track, proceed, token, title]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (playing) {
        API.updateProfileTrack(
          track.id,
          IN_PROGRESS,
          video.current.currentTime,
        );
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [playing, track]);

  // Video EVENTS
  function onLoaded() {
    if (video.current.currentTime < track.secondsElapsed) {
      video.current.currentTime = track.secondsElapsed;
    }
  }

  function onEnded() {
    onEndedTrack(track);
    setPlaying(false);
    API.updateProfileTrack(track.id, FINISHED, 0);
  }

  function onPause() {
    setPlaying(false);
    API.updateProfileTrack(track.id, IN_PROGRESS, video.current.currentTime);
  }

  function onPlay() {
    setPlaying(true);
    API.updateProfileTrack(track.id, IN_PROGRESS, video.current.currentTime);
  }

  if (track) {
    return (
      <>
        <Box>
          <video
            ref={video}
            controls={true}
            controlsList="nodownload"
            style={{ width: "100%" }}
            onCanPlay={onLoaded}
            onEnded={onEnded}
            onPause={onPause}
            onPlay={onPlay}
          />
        </Box>
      </>
    );
  }

  return t("loading");
}
