const BASE_PATH = "/api/v1";
const API = {
  token: "",
};

function request(url, method, body) {
  let headers = {
    "Content-Type": "application/json",
  };
  if (API.token !== "") {
    headers = {
      ...headers,
      Authorization: `Bearer ${API.token}`,
    };
  }

  let init = {
    method: method,
    headers,
  };
  if (method !== "get" && body) {
    init = { ...init, body: JSON.stringify(body) };
  }

  return fetch(`${BASE_PATH}${url}`, init).then((r) => {
    if (method === "delete" && r.status === 204) {
      return Promise.resolve();
    }
    if (!r.ok) {
      throw new Error(r.statusText);
    }
    return r.json();
  });
}

API.login = function (login, password) {
  return request("/security/login", "post", { login, password });
};

API.register = function (email, captchaValue) {
  return request("/security/register", "post", { email, captchaValue });
};

API.requestReset = function (email, captchaValue) {
  return request("/security/requestReset", "post", { email, captchaValue });
};

API.reset = function (email, newPassword, magicLink, captchaValue) {
  return request("/security/reset", "post", {
    email,
    magicLink,
    newPassword,
    captchaValue,
  });
};

API.getNews = function () {
  return request("/news", "get");
};

API.getPlaylists = function () {
  return request("/playlists", "get");
};

API.getPlaylistDetails = function (id) {
  return request(`/playlists/${id}/details`, "get");
};

API.search = function (query) {
  return request(`/playlists/search?query=${query}`, "get");
};

API.getTracks = function (id) {
  return request(`/playlists/${id}/tracks`, "get");
};

API.getProfilePlaylists = function () {
  return request("/profiles/playlists", "get");
};

API.getProfileTracks = function (id) {
  return request(`/profiles/playlists/${id}/tracks`, "get");
};

API.updateProfilePlaylist = function (id, status) {
  return request(`/profiles/playlists/${id}`, "put", {
    playlistId: id,
    status,
  });
};

API.updateProfileTrack = function (id, status, secondsElapsed) {
  return request(`/profiles/tracks/${id}`, "put", {
    trackId: id,
    secondsElapsed,
    status,
  });
};

API.getPlaylistContent = function (id, filename) {
  let headers = {};
  if (API.token !== "") {
    headers = {
      ...headers,
      Authorization: `Bearer ${API.token}`,
    };
  }

  return fetch(`${BASE_PATH}/files/playlists/${id}/${filename}`, {
    headers,
    method: "get"
  }).then(r => {
    if (!r.ok) {
      throw new Error(r.statusText);
    }
    return r.text()
  });
};

// admin API
API.scan = function () {
  return request("/files/scan", "post");
};

API.getAdminProfiles = function () {
  return request("/admin/profiles", "get");
};

API.getAdminProfilePlaylists = function (id) {
  return request(`/admin/profiles/${id}`, "get");
};

API.updateAdminProfilePlaylist = function (profileId, playlistId, status) {
  return request(
    `/admin/profiles/${profileId}/playlists/${playlistId}`,
    "put",
    {
      playlistId: playlistId,
      status,
    },
  );
};

API.deleteAdminProfilePlaylist = function (profileId, playlistId, status) {
  return request(
    `/admin/profiles/${profileId}/playlists/${playlistId}`,
    "delete",
  );
};

API.getOutbox = function (page, size) {
  return request(`/outbox?page=${page}&size=${size}`, "get");
};

API.resendOutbox = function (id) {
  return request(`/outbox/${id}`, "put");
};

export default API;
