import { createContext, useEffect, useState } from "react";
import API from "../api";

const SecurityContext = createContext(null);

function SecurityProvider({ children }) {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isReady, setReady] = useState(false);
  const [login, setLogin] = useState("");
  const [token, setToken] = useState("");

  const [isFail, setFail] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const storageLogin = localStorage.getItem("login");
    const storageToken = localStorage.getItem("token");
    if (storageLogin !== null && storageToken !== null) {
      setLoggedIn(true);
      setLogin(storageLogin);
      setToken(storageToken);
      API.token = storageToken;
    }
    setReady(true);
  }, []);

  function doLogin(login, password) {
    API.login(login, password)
      .then((d) => {
        localStorage.setItem("login", login);
        localStorage.setItem("token", d.token);
        setLoggedIn(true);
        setLogin(login);
        setToken(d.token);
        API.token = d.token;
      })
      .catch((e) => {
        setFail(true);
        setError("Cannot login! ", e);
      });
  }

  function doLogout() {
    localStorage.removeItem("login");
    localStorage.removeItem("token");
    setLoggedIn(false);
    setLogin("");
    setToken("");
  }

  return (
    <SecurityContext.Provider
      value={{
        isReady,
        isLoggedIn,
        login,
        token,

        isFail,
        error,
        doLogin,

        doLogout,
      }}
    >
      {children}
    </SecurityContext.Provider>
  );
}

export { SecurityContext, SecurityProvider };
