import { useContext, useEffect, useReducer, useState } from "react";
import { SecurityContext } from "../../service/SecurityService";
import API from "../../api";
import {
  Alert,
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Block, Check, ContentCopy, FileCopy } from "@mui/icons-material";
import { DEFAULT_PAGE_SIZE } from "../../api/const";
import CopyToClipboard from "react-copy-to-clipboard";

export function OutboxAdmin() {
  const { isReady } = useContext(SecurityContext);
  const [page, setPage] = useState(null);
  const [outboxList, dispatch] = useReducer(reducer, []);
  const [error, setError] = useState("");
  const [copied, setCopied] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    let interval1 = setInterval(() => setCopied(0), 3000);

    return () => {
      clearInterval(interval1);
    };
  }, []);

  function reducer(state, action) {
    switch (action.type) {
      case "ADD_ALL": {
        const { outboxList } = action;
        return outboxList;
      }
      case "RESEND": {
        const { update } = action;
        return state.map((o) => {
          if (o.id === update.id) {
            return update;
          }
          return o;
        });
      }
      default:
        return state;
    }
  }

  async function loadPage(pageNumber) {
    try {
      const p = await API.getOutbox(pageNumber, DEFAULT_PAGE_SIZE);
      setPage(p);
      dispatch({ type: "ADD_ALL", outboxList: p.content });
      setError("");
    } catch (e) {
      setError(`error: ${e}`);
    }
  }

  async function resend(id) {
    try {
      const update = await API.resendOutbox(id);
      dispatch({ type: "RESEND", update });
      setError("");
    } catch (e) {
      setError(`error: ${e}`);
    }
  }

  useEffect(() => {
    if (isReady) {
      loadPage(0);
    }
  }, [isReady]);

  if (error !== "") {
    return <Alert severity="error">{error}</Alert>;
  }
  if (outboxList && page) {
    return (
      <>
        <Pagination
          count={page.totalPages}
          onChange={(e, v) => loadPage(v - 1)}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("id")}</TableCell>
              <TableCell>{t("recipient")}</TableCell>
              <TableCell>{t("subject")}</TableCell>
              <TableCell>{t("message")}</TableCell>
              <TableCell>{t("processed")}</TableCell>
              <TableCell>{t("failed")}</TableCell>
              <TableCell>{t("response")}</TableCell>
              <TableCell>{t("createdAt")}</TableCell>
              <TableCell>{t("lastUpdated")}</TableCell>
              <TableCell>{t("actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outboxList.map((message) => (
              <TableRow key={message.id}>
                <EllipsisTableCell>{message.id}</EllipsisTableCell>
                <EllipsisTableCell>{message.recipient}</EllipsisTableCell>
                <EllipsisTableCell>{message.subject}</EllipsisTableCell>
                <EllipsisTableCell tooltip={message.message}>
                  <CopyToClipboard
                    text={message.message}
                    onCopy={() => setCopied(message.id)}
                  >
                    <span>
                      {copied === message.id ? <FileCopy /> : <ContentCopy />}{" "}
                      {message.message}
                    </span>
                  </CopyToClipboard>
                </EllipsisTableCell>
                <EllipsisTableCell>
                  {message.processed ? (
                    <Check color="success" />
                  ) : (
                    <Block color="error" />
                  )}
                </EllipsisTableCell>
                <EllipsisTableCell>
                  {message.failed ? (
                    <Check color="success" />
                  ) : (
                    <Block color="error" />
                  )}
                </EllipsisTableCell>
                <EllipsisTableCell tooltip={message.response}>
                  {message.response}
                </EllipsisTableCell>
                <EllipsisTableCell>{message.createdAt}</EllipsisTableCell>
                <EllipsisTableCell>{message.lastUpdated}</EllipsisTableCell>
                <EllipsisTableCell>
                  <Button onClick={() => resend(message.id)}>
                    {t("resend")}
                  </Button>
                </EllipsisTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
  return "Loading";
}

function EllipsisTableCell({ children, tooltip }) {
  const tableCell = (
    <TableCell
      style={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "300px",
        overflow: "hidden",
      }}
    >
      {children}
    </TableCell>
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{tableCell}</Tooltip>;
  }
  return tableCell;
}
