import { Alert, Box, Button } from "@mui/material";
import API from "../../api";
import { useContext, useState } from "react";
import { SecurityContext } from "../../service/SecurityService";

export function CoursesManagementAdmin() {
  const { isReady } = useContext(SecurityContext);
  const [message, setMessage] = useState("");

  async function handleScan() {
    if (isReady) {
      try {
        const result = await API.scan();
        setMessage(`result: ${result}`);
      } catch (e) {
        setMessage(`exception: ${e}`);
      }
    }
  }

  return (
    <Box>
      <Button onClick={handleScan}>Scan</Button>
      {message && <Alert color="info">{message}</Alert>}
    </Box>
  );
}
