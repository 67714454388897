import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Route, Routes } from "react-router-dom";
import { Login } from "./components/security/Login";
import { Layout } from "./components/Layout";
import { SecurityProvider } from "./service/SecurityService";
import { Playlist } from "./components/Playlist";
import { createTheme, ThemeProvider } from "@mui/material";
import { Library } from "./components/Library";
import { Signup } from "./components/security/Signup";
import { ProfilesAdmin } from "./components/admin/ProfilesAdmin";
import { CoursesAdmin } from "./components/admin/CoursesAdmin";
import { BreadcrumbProvider } from "./service/BreadcrumbService";
import { useTranslation } from "react-i18next";
import { Reset } from "./components/security/Reset";
import { RequestReset } from "./components/security/RequestReset";
import { AdminLayout } from "./components/admin/AdminLayout";
import { OutboxAdmin } from "./components/admin/OutboxAdmin";
import { CoursesManagementAdmin } from "./components/admin/CoursesManagementAdmin";

const abTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00b2d7",
    },
    secondary: {
      main: "rgb(201,158,93)",
    },
  },
});

function App() {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={abTheme}>
      <SecurityProvider>
        <BreadcrumbProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/request-reset" element={<RequestReset />} />
            <Route path="/confirm" element={<Reset message={t("confirm")} />} />
            <Route
              path="/password-reset"
              element={<Reset message={t("resetPassword")} />}
            />
            <Route path="/app" element={<Layout />}>
              <Route index element={<Library />} />
              <Route path="/app/playlists/:id" element={<Playlist />} />
              <Route path="/app/admin" element={<AdminLayout />}>
                <Route path="/app/admin" element={<ProfilesAdmin />} />
                <Route
                  path="/app/admin/profiles/:id"
                  element={<CoursesAdmin />}
                />
                <Route path="/app/admin/outbox" element={<OutboxAdmin />} />
                <Route
                  path="/app/admin/courses"
                  element={<CoursesManagementAdmin />}
                />
              </Route>
            </Route>
          </Routes>
        </BreadcrumbProvider>
      </SecurityProvider>
    </ThemeProvider>
  );
}

export default App;
