import {
  Alert,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import API from "../../api";
import { SecurityContext } from "../../service/SecurityService";
import { Block, Check } from "@mui/icons-material";
import { BreadcrumbContext } from "../../service/BreadcrumbService";

export function ProfilesAdmin() {
  const { t } = useTranslation();
  const { isReady } = useContext(SecurityContext);
  const [profiles, setProfiles] = useState([]);
  const [error, setError] = useState("");

  const { dispatchBreadcrumb } = useContext(BreadcrumbContext);
  const title = t("adminPage");
  const dispatchBCCallback = useCallback(dispatchBreadcrumb, [
    title,
    dispatchBreadcrumb,
  ]);
  useEffect(() => {
    dispatchBCCallback({ type: "PARENT", title, parent: null });
  }, [title, dispatchBCCallback]);

  useEffect(() => {
    async function load() {
      try {
        const p = await API.getAdminProfiles();
        setProfiles(p);

        setError("");
      } catch (e) {
        setError(`error: ${e}`);
      }
    }

    if (isReady) {
      load();
    }
  }, [isReady]);

  if (error !== "") {
    return <Alert severity="error">{error}</Alert>;
  }
  return (
    <>
      <Typography variant="h5" component="h5">
        {t("adminPage")}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("login")}</TableCell>
              <TableCell>{t("activated")}</TableCell>
              <TableCell>{t("isAdmin")}</TableCell>
              <TableCell>{t("courses")}</TableCell>
              <TableCell>{t("lastUpdated")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((p) => (
              <ProfileRow key={p.id} profile={p} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function ProfileRow({ profile }) {
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell>{profile.login}</TableCell>
      <TableCell>
        {profile.activated ? (
          <Check color="success" />
        ) : (
          <Block color="error" />
        )}
      </TableCell>
      <TableCell>
        {profile.isAdmin ? <Check color="success" /> : <Block color="error" />}
      </TableCell>
      <TableCell>
        <Link href={`/app/admin/profiles/${profile.id}`}>{t("courses")}</Link>
      </TableCell>
      <TableCell>
        {profile.lastUpdated}
      </TableCell>
    </TableRow>
  );
}
