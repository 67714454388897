import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router";

export function AdminLayout() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <List>
          <ListItem>
            <ListItemButton onClick={() => navigate("/app/admin")}>
              <ListItemText primary="Users" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate("/app/admin/outbox")}>
              <ListItemText primary="Email Notifications" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton onClick={() => navigate("/app/admin/courses")}>
              <ListItemText primary="Courses" />
            </ListItemButton>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} md={9}>
        <Outlet />
      </Grid>
    </Grid>
  );
}
