import { useParams } from "react-router";
import {
  Alert,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SecurityContext } from "../../service/SecurityService";
import { Block, Check } from "@mui/icons-material";
import API from "../../api";
import { NOT_PLAYED } from "../../api/const";
import { BreadcrumbContext } from "../../service/BreadcrumbService";

export function CoursesAdmin() {
  const reducer = (state, action) => {
    switch (action.type) {
      case "ADD_ALL":
        const { profile } = action;
        return profile;
      case "UPDATE_COURSE":
        const { update } = action;
        return {
          ...state,
          playlists: state.playlists.map((c) => {
            if (c.id === update.playlistId) {
              return { ...c, allowed: true };
            }
            return c;
          }),
        };
      case "DELETE_COURSE":
        const { playlistId } = action;
        return {
          ...state,
          playlists: state.playlists.map((c) => {
            if (c.id === playlistId) {
              return { ...c, allowed: false, status: NOT_PLAYED };
            }
            return c;
          }),
        };
      default:
        return state;
    }
  };
  const { id } = useParams();
  const { t } = useTranslation();
  const [profile, dispatch] = useReducer(reducer, null);
  const { isReady } = useContext(SecurityContext);
  const [error, setError] = useState("");

  const { dispatchBreadcrumb } = useContext(BreadcrumbContext);
  const title = t("adminProfilePage");
  const currentTitle = t("adminPage");
  const dispatchBCCallback = useCallback(dispatchBreadcrumb, [
    title,
    currentTitle,
    dispatchBreadcrumb,
  ]);
  useEffect(() => {
    dispatchBCCallback({
      type: "PARENT",
      title,
      parent: {
        href: "/app/admin",
        title: currentTitle,
      },
    });
  }, [title, currentTitle, dispatchBCCallback]);

  useEffect(() => {
    async function load() {
      try {
        const pd = await API.getAdminProfilePlaylists(id);
        dispatch({ type: "ADD_ALL", profile: pd });

        setError("");
      } catch (e) {
        setError(`error: ${e}`);
      }
    }

    if (isReady) {
      load();
    }
  }, [isReady, id]);

  async function allowCourse(playlistId) {
    const update = await API.updateAdminProfilePlaylist(
      id,
      playlistId,
      NOT_PLAYED,
    );
    dispatch({ type: "UPDATE_COURSE", update });
  }

  async function removeCourse(playlistId) {
    await API.deleteAdminProfilePlaylist(id, playlistId, NOT_PLAYED);
    dispatch({ type: "DELETE_COURSE", playlistId });
  }

  if (!profile) {
    return "Loading";
  }

  if (error !== "") {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      <Typography variant="h5" component="h5">
        {profile.login}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("title")}</TableCell>
              <TableCell>{t("status")}</TableCell>
              <TableCell>{t("allowed")}</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profile.playlists.map((c) => (
              <CourseRow
                key={c.id}
                onAllow={allowCourse}
                onRemove={removeCourse}
                course={c}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function CourseRow({ course, onAllow, onRemove }) {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell>{course.title}</TableCell>
      <TableCell>{course.status}</TableCell>
      <TableCell>
        {course.allowed ? <Check color="success" /> : <Block color="error" />}
      </TableCell>
      <TableCell>
        {course.allowed ? (
          <Button onClick={() => onRemove(course.id)}>{t("remove")}</Button>
        ) : (
          <Button onClick={() => onAllow(course.id)}>{t("allow")}</Button>
        )}
      </TableCell>
    </TableRow>
  );
}
