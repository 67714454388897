import {
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import { SecurityContext } from "../service/SecurityService";
import { Outlet, useNavigate } from "react-router";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Home } from "@mui/icons-material";
import { BreadcrumbContext } from "../service/BreadcrumbService";

function Layout() {
  const { doLogout } = useContext(SecurityContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { breadcrumbs } = useContext(BreadcrumbContext);

  return (
    <Box component="main" sx={{ my: 7 }}>
      <AppBar position="fixed">
        <Toolbar>
          <a href="/app">
            <img src="/favicon-32x32.png" alt="logo" />
          </a>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, pl: 2 }}>
            <a
              href="/app"
              style={{
                color: "white",
                textDecoration: "none",
              }}
            >
              TTKS Courses
            </a>
          </Typography>
          <Button
            color="inherit"
            onClick={() => {
              navigate("/");
            }}
          >
            {t("myLibrary")}
          </Button>
          <Button
            color="inherit"
            onClick={() => {
              doLogout();
              navigate("/");
            }}
          >
            {t("logout")}
          </Button>
        </Toolbar>
      </AppBar>

      <Box sx={{ my: 10, mx: 4 }}>
        <Breadcrumbs sx={{ mb: 2 }}>
          <BreadcrumbLink
            href={breadcrumbs.home.href}
            title={breadcrumbs.home.title}
            home={breadcrumbs.home.home}
          />
          {breadcrumbs.parent && (
            <BreadcrumbLink
              href={breadcrumbs.parent.href}
              title={breadcrumbs.parent.title}
            />
          )}
        </Breadcrumbs>
        <Outlet />
      </Box>
    </Box>
  );
}

function BreadcrumbLink({ href, title, home }) {
  return (
    <Link
      underline="hover"
      sx={{ display: "flex", alignItems: "center" }}
      color="inherit"
      href={href}
    >
      {home ? <Home sx={{ mr: 0.5 }} fontSize="inherit" /> : ""}
      {title}
    </Link>
  );
}

export { Layout };
