import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import { SecurityContext } from "../../service/SecurityService";
import { Navigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import API from "../../api";
import ReCAPTCHA from "react-google-recaptcha";

export function Reset({ message }) {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { isLoggedIn, isFail, error, doLogin } = useContext(SecurityContext);
  const { t } = useTranslation();
  const email = searchParams.get("email");
  const magicLink = searchParams.get("magic");
  const recaptcha = useRef();

  async function handleSubmit(e) {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setErrorMsg("Please verify the reCAPTCHA!");
    } else {
      try {
        const { result } = await API.reset(
          email,
          password,
          magicLink,
          captchaValue,
        );
        if (result === "ok") {
          doLogin(email, password);
        } else {
          setErrorMsg(`error: link is outdated`);
        }
      } catch (e) {
        setErrorMsg(`error: ${e}`);
      }
    }
  }

  if (isLoggedIn) {
    return <Navigate to="/app" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h2" variant="h5">
          {message}
        </Typography>
        {isFail && <Alert severity="error">{error}</Alert>}
        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("password")}
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="passwordConfirmation"
            label={t("passwordConfirmation")}
            type="password"
            id="passwordConfirmation"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("signin")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
