import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useRef, useState } from "react";
import { SecurityContext } from "../../service/SecurityService";
import { Navigate } from "react-router";
import { useTranslation } from "react-i18next";
import API from "../../api";
import ReCAPTCHA from "react-google-recaptcha";

export function RequestReset() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const { isLoggedIn } = useContext(SecurityContext);
  const { t } = useTranslation();
  const recaptcha = useRef();

  async function handleSubmit(e) {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setError("Please verify the reCAPTCHA!");
    } else {
      try {
        const { result } = await API.requestReset(email, captchaValue);
        if (result === "ok") {
          setMessage(t("checkEmail"));
        } else {
          setError(`error: link is outdated`);
        }
      } catch (e) {
        setError(`error: ${e}`);
      }
    }
  }

  if (isLoggedIn) {
    return <Navigate to="/app" />;
  }

  if (message) {
    return (
      <>
        <Alert color="info">{message}</Alert>
        <Button
          onClick={() => {
            setMessage("");
            setEmail("");
          }}
        >
          {t("close")}
        </Button>
      </>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h2" variant="h5">
          {t("requestReset")}
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("requestReset")}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
