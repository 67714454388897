import { useNavigate, useParams } from "react-router";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import API from "../api";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  ArrowUpward,
  Check,
  MoreHoriz,
  PlayArrow,
  Undo,
  VideoFile,
} from "@mui/icons-material";
import { EmbeddedPlayer } from "./EmbeddedPlayer";
import { FINISHED, IN_PROGRESS, NOT_PLAYED } from "../api/const";
import { toHHMMSS } from "../api/utils";
import { ContextMenu } from "./ContextMenu";
import { SecurityContext } from "../service/SecurityService";
import * as PropTypes from "prop-types";
import { PlaylistDetails } from "./PlaylistDetails";
import { useTranslation } from "react-i18next";
import { BreadcrumbContext } from "../service/BreadcrumbService";

function reducer(state, action) {
  switch (action.type) {
    case "ADD_ALL": {
      return action.tracks;
    }
    case "UPDATE": {
      const { id, track } = action;
      return state.map((tr) =>
        tr.id === id
          ? {
              ...tr,
              status: track.status,
              secondsElapsed: track.secondsElapsed,
            }
          : tr,
      );
    }
    default:
      break;
  }
}

PlaylistDetails.propTypes = { playlistId: PropTypes.string };

export function Playlist() {
  const { id } = useParams();
  const { isReady } = useContext(SecurityContext);
  const [tracks, dispatch] = useReducer(reducer, []);
  const [error, setError] = useState("");
  const [currentTrack, setCurrentTrack] = useState();
  const [proceed, setProceed] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [tabIdx, setTabIdx] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuTrack, setMenuTrack] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (track) => {
    setMenuTrack(track);
  };

  const { dispatchBreadcrumb } = useContext(BreadcrumbContext);
  const title = t("currentCourse", { title: id });
  const currentTitle = t("courses");
  const dispatchBCCallback = useCallback(dispatchBreadcrumb, [
    title,
    currentTitle,
    dispatchBreadcrumb,
  ]);
  useEffect(() => {
    dispatchBCCallback({
      type: "PARENT",
      title,
      parent: {
        href: "/app",
        title: currentTitle,
      },
    });
  }, [title, currentTitle, dispatchBCCallback]);

  useEffect(() => {
    async function load() {
      try {
        const tracks = await API.getTracks(id);
        dispatch({ type: "ADD_ALL", tracks });

        const profileTracks = await API.getProfileTracks(id);
        profileTracks.map((t) =>
          dispatch({ type: "UPDATE", id: t.trackId, track: t }),
        );
        setError("");
      } catch (e) {
        setError(`"error": ${e}`);
      }
    }

    if (isReady) {
      load();
    }
  }, [id, isReady]);

  useEffect(() => {
    const foundTrack = tracks.find((t) => t.status === IN_PROGRESS);
    if (typeof foundTrack === "undefined") {
      setCurrentTrack(tracks[0]);
    } else {
      setCurrentTrack(foundTrack);
    }
  }, [tracks]);

  function onEndedTrack(track) {
    const idx = tracks.findIndex((t) => t.id === track.id);
    dispatch({
      type: "UPDATE",
      id: track.id,
      track: {
        ...track,
        status: FINISHED,
        secondsElapsed: 0,
      },
    });
    if (idx >= 0 && idx < tracks.length) {
      setProceed(true);
      const nextTrack = tracks[idx + 1];
      dispatch({
        type: "UPDATE",
        id: nextTrack.id,
        track: {
          ...nextTrack,
          status: IN_PROGRESS,
          secondsElapsed: 0,
        },
      });
    }
  }

  function onStartPlaying() {
    API.updateProfilePlaylist(id, IN_PROGRESS);
  }

  if (error !== "") {
    return <Alert severity="error">{error}</Alert>;
  }

  function getIcon(status) {
    switch (status) {
      case IN_PROGRESS:
        return <PlayArrow color="success" />;
      case FINISHED:
        return <Check color="disabled" />;
      default:
        return <VideoFile color="primary" />;
    }
  }

  // Menu Actions
  function menuCallback(status) {
    API.updateProfileTrack(menuTrack.id, status, 0);
    dispatch({
      type: "UPDATE",
      id: menuTrack.id,
      track: {
        ...menuTrack,
        status: status,
        secondsElapsed: 0,
      },
    });
  }

  const contextMenuActions = [
    {
      status: FINISHED,
      title: t("markAsPlayed"),
      icon: <Check />,
      callback: () => menuCallback(FINISHED),
    },
    {
      status: NOT_PLAYED,
      title: t("reset"),
      icon: <Undo />,
      callback: () => menuCallback(NOT_PLAYED),
    },
  ];

  function handleTabChange(event, newTabIdx) {
    setTabIdx(newTabIdx);
  }

  function statusText(track) {
    const status = t(track.status || NOT_PLAYED);
    const timestamp = track.secondsElapsed
      ? toHHMMSS(track.secondsElapsed)
      : "00:00";

    return t("trackStatus", { status, timestamp });
  }

  const playlistBox = (
    <>
      <List>
        <ListItem onClick={() => navigate("/app")}>
          <ListItemButton>
            <ListItemIcon>
              <ArrowUpward />
            </ListItemIcon>
            <ListItemText primary=".." secondary={t("updir")} />
          </ListItemButton>
        </ListItem>
        {tracks.map((track) => (
          <ListItem
            key={track.id}
            disablePadding
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <MoreHoriz
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    handleClick(track);
                  }}
                />
              </IconButton>
            }
          >
            <ListItemButton onClick={() => setCurrentTrack(track)}>
              <ListItemIcon>{getIcon(track.status)}</ListItemIcon>
              <ListItemText
                primary={
                  track === currentTrack ? (
                    <Box fontWeight="bold">{track.title}</Box>
                  ) : (
                    track.title
                  )
                }
                secondary={statusText(track)}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
  const DISABLE_DESCRIPTION = true;
  const description = DISABLE_DESCRIPTION ? (
    currentTrack && currentTrack.title
  ) : (
    <PlaylistDetails playlistId={id} />
  );
  const embeddedPlayer = (
    <EmbeddedPlayer
      track={currentTrack}
      proceed={proceed}
      onEndedTrack={onEndedTrack}
      onStartPlaying={onStartPlaying}
    />
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5">
            {currentTrack && currentTrack.title}
          </Typography>
          {embeddedPlayer}
        </Grid>
        <Grid item xs={12} md={4}>
          <Tabs value={tabIdx} onChange={handleTabChange}>
            <Tab label={t("playlist")} id={0} />
            <Tab label={t("description")} id={1} />
          </Tabs>
          {tabIdx === 0 && playlistBox}
          {tabIdx === 1 && description}
        </Grid>
      </Grid>

      <ContextMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        contextMenuActions={contextMenuActions}
      />
    </>
  );
}
