import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import API from "../api";
import {
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Check,
  MenuBook,
  MoreHoriz,
  PlayArrow,
  Undo,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { FINISHED, IN_PROGRESS, NOT_PLAYED } from "../api/const";
import { ContextMenu } from "./ContextMenu";
import { SecurityContext } from "../service/SecurityService";
import { useTranslation } from "react-i18next";
import { BreadcrumbContext } from "../service/BreadcrumbService";

function reducer(state, action) {
  switch (action.type) {
    case "ADD_ALL": {
      return action.playlists;
    }
    case "UPDATE": {
      const { id, profilePlaylist } = action;
      return state.map((p) =>
        p.id === id
          ? {
              ...p,
              status: profilePlaylist.status,
            }
          : p,
      );
    }
    default:
      break;
  }
  return state;
}

export function Library() {
  const { isReady } = useContext(SecurityContext);
  const [playlists, dispatch] = useReducer(reducer, []);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPlaylist, setMenuPlaylist] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (playlist) => {
    setMenuPlaylist(playlist);
  };

  const { dispatchBreadcrumb } = useContext(BreadcrumbContext);
  const title = t("courses");
  const dispatchBCCallback = useCallback(dispatchBreadcrumb, [
    title,
    dispatchBreadcrumb,
  ]);
  useEffect(() => {
    dispatchBCCallback({ type: "PARENT", title, parent: null });
  }, [title, dispatchBCCallback]);

  useEffect(() => {
    async function load() {
      try {
        const playlists = await API.getPlaylists();
        dispatch({ type: "ADD_ALL", playlists });

        const profilePlaylists = await API.getProfilePlaylists();
        profilePlaylists.map((p) =>
          dispatch({ type: "UPDATE", id: p.playlistId, profilePlaylist: p }),
        );
        setError("");
      } catch (e) {
        setError(`error: ${e}`);
      }
    }

    if (isReady) {
      load();
    }
  }, [isReady]);

  function getIcon(status) {
    switch (status) {
      case IN_PROGRESS:
        return <PlayArrow color="success" />;
      case FINISHED:
        return <Check color="disabled" />;
      default:
        return <MenuBook color="primary" />;
    }
  }

  // MenuActions
  async function menuCallback(status) {
    await API.updateProfilePlaylist(menuPlaylist.id, status);
    dispatch({
      type: "UPDATE",
      id: menuPlaylist.id,
      profilePlaylist: {
        ...menuPlaylist,
        status: status,
      },
    });
  }

  const contextMenuActions = [
    {
      status: FINISHED,
      title: t("markAsPlayed"),
      icon: <Check />,
      callback: () => menuCallback(FINISHED),
    },
    {
      status: NOT_PLAYED,
      title: t("reset"),
      icon: <Undo />,
      callback: () => menuCallback(NOT_PLAYED),
    },
  ];

  if (error !== "") {
    return <Alert severity="error">{error}</Alert>;
  }
  if (!playlists || playlists.length === 0) {
    return <Alert color="info">{t("noResultsFound")}</Alert>;
  }
  return (
    <>
      <List>
        {playlists.map((p) => (
          <ListItem
            key={p.id}
            disablePadding
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <MoreHoriz
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    handleClick(p);
                  }}
                />
              </IconButton>
            }
          >
            <ListItemButton onClick={() => navigate(`/app/playlists/${p.id}`)}>
              <ListItemIcon>{getIcon(p.status)}</ListItemIcon>
              <ListItemText
                primary={p.title}
                secondary={`status: ${p.status || NOT_PLAYED}`}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <ContextMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        contextMenuActions={contextMenuActions}
      />
    </>
  );
}
